var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-pen-to-square",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("Edit, move or delete this account.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('button', {
          staticClass: "btn btn-primary mr-3",
          attrs: {
            "data-cy": "move-account-btn"
          },
          on: {
            "click": function click($event) {
              _vm.modals.move = {};
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-arrow-up"
        }), _vm._v(" Move Account ")]), !_vm.selectedAccount.deleted ? _c('button', {
          staticClass: "btn btn-danger",
          attrs: {
            "data-cy": "delete-account-btn"
          },
          on: {
            "click": function click($event) {
              _vm.modals.remove = _vm.selectedAccount;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ban fa-fw"
        }), _vm._v(" Delete Account ")]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [[_vm._v("Edit Account")]], 2), Object.values(_vm.form).length > 0 ? _c('AccountForm', {
    attrs: {
      "error": _vm.validationErrors,
      "disabled": _vm.accountLoadingAction.update,
      "account-id": _vm.selectedAccount._id
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }) : _vm._e(), !_vm.supplierLoadingAction.list ? _c('div', {
    staticClass: "form-group text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.accountLoadingAction.update
    },
    on: {
      "click": _vm.onUpdateAccount
    }
  }, [_vm.accountLoadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Account")])])]) : _vm._e(), _c('ConfirmModal', {
    attrs: {
      "id": "delete-account",
      "open": !!_vm.modals.remove,
      "title": "Delete Account",
      "text": "Please confirm you would like to remove account <strong>".concat(_vm.selectedAccount.name, "</strong>.")
    },
    on: {
      "close": function close($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemove
    }
  }), _c('ConfirmModal', {
    attrs: {
      "id": "move-account",
      "open": !!_vm.modals.move,
      "title": "Move Account",
      "prevent": ""
    },
    on: {
      "close": function close($event) {
        _vm.modals.move = false;
      },
      "submit": _vm.onMove
    }
  }, [!_vm.loading && _vm.modals.move ? _c('LookaheadSearch', {
    attrs: {
      "action": function action(query, params) {
        return _vm.onSearchAssets(query, params);
      },
      "results": _vm.assetResults,
      "loading": _vm.assetLoadingAction.find,
      "title": 'Asset'
    },
    on: {
      "select": function select(asset) {
        return _vm.onSelectAsset(asset);
      }
    },
    scopedSlots: _vm._u([{
      key: "result",
      fn: function fn(slotProps) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', [_c('span', [_vm._v(_vm._s(slotProps.data.siteName) + " ")])]), _c('div', [_c('span', {
          staticClass: "badge badge-primary text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(slotProps.data.address.streetAddress) + ", " + _vm._s(slotProps.data.address.postCode) + " ")])])])];
      }
    }], null, false, 3649439794)
  }) : _vm._e(), _vm.loading ? _c('Spinner', [_vm._v("Updating...")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }