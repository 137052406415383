<template>
  <div>
    <TabTitle class="mb-4" icon="fa-pen-to-square" action-position="right">
      <template>Edit Account</template>
      <template #sub-title>Edit, move or delete this account.</template>
      <template #actions>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-primary mr-3" data-cy="move-account-btn" @click="modals.move = {}">
            <i class="fas fa-arrow-up"></i> Move Account
          </button>
          <button v-if="!selectedAccount.deleted" class="btn btn-danger" data-cy="delete-account-btn" @click="modals.remove = selectedAccount">
            <i class="fa fa-ban fa-fw"></i> Delete Account
          </button>
        </div>
      </template>
    </TabTitle>

    <AccountForm
      v-if="Object.values(form).length > 0"
      v-model="form"
      :error="validationErrors"
      :disabled="accountLoadingAction.update"
      :account-id="selectedAccount._id"
    />

    <div v-if="!supplierLoadingAction.list" class="form-group text-right">
      <button type="submit" class="btn btn-primary" :disabled="accountLoadingAction.update" @click="onUpdateAccount">
        <span v-if="accountLoadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
        <span v-else><i class="fa fa-check-circle mr-1"></i> Update Account</span>
      </button>
    </div>

    <ConfirmModal
      id="delete-account"
      :open="!!modals.remove"
      title="Delete Account"
      :text="`Please confirm you would like to remove account <strong>${selectedAccount.name}</strong>.`"
      @close="modals.remove = false"
      @submit="onRemove"
    />
    <ConfirmModal id="move-account" :open="!!modals.move" title="Move Account" prevent @close="modals.move = false" @submit="onMove">
      <LookaheadSearch
        v-if="!loading && modals.move"
        :action="(query, params) => onSearchAssets(query, params)"
        :results="assetResults"
        :loading="assetLoadingAction.find"
        :title="'Asset'"
        @select="asset => onSelectAsset(asset)"
      >
        <template v-slot:result="slotProps">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>{{ slotProps.data.siteName }} </span>
            </div>
            <div>
              <span class="badge badge-primary text-capitalize mr-2">
                {{ slotProps.data.address.streetAddress }}, {{ slotProps.data.address.postCode }}
              </span>
            </div>
          </div>
        </template>
      </LookaheadSearch>
      <Spinner v-if="loading">Updating...</Spinner>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import AccountForm from '@/components/forms/AccountForm';
import ConfirmModal from '@/components/ConfirmModal';
import LookaheadSearch from '@/components/LookaheadSearch';
import Spinner from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetAccountEdit',
  components: {
    AccountForm,
    ConfirmModal,
    LookaheadSearch,
    Spinner,
    TabTitle
  },
  data() {
    return {
      form: {},
      modals: {
        remove: null,
        move: null
      },
      assets: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      selectedAccount: 'asset/selectedAccount',
      accountLoadingAction: 'account/loadingAction',
      accountError: 'account/errorAction',
      assetLoadingAction: 'asset/loadingAction',
      assetResults: 'asset/findAssets',
      validationErrors: 'account/validationErrors',
      supplierLoadingAction: 'supplier/loadingAction'
    })
  },
  async mounted() {
    this.form = { ...this.selectedAccount };
  },
  methods: {
    ...mapActions({
      updateAccount: 'account/update',
      removeAccount: 'account/remove',
      getAccounts: 'asset/accounts',
      listAssets: 'asset/list',
      searchAssets: 'asset/search'
    }),
    ...mapMutations({
      setSelectedAccount: 'asset/SET_SELECTED_ACCOUNT',
      resetFindAssets: 'asset/RESET_FIND_ASSETS_STATE'
    }),
    async onUpdateAccount() {
      await this.updateAccount({
        id: this.selectedAccount._id,
        data: {
          ...this.form,
          parentAccountId: this.form.parentAccountId || null
        }
      });

      if (Object.keys(this.validationErrors).length > 0) {
        this.$toasted.error('Unable to update account');
      }

      if (!this.accountError.update) {
        this.$toasted.success('Updated account successfully');
      }

      this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1', $deleted: true } } });
    },
    async onSearchAssets(query, params) {
      return await this.searchAssets({ query, params });
    },
    async onSelectAsset(asset) {
      this.modals.move.selectedAssetId = asset._id;
      this.modals.move.selectedEntityId = asset.entityId;
    },
    async onMove() {
      const { selectedAssetId, selectedEntityId } = this.modals.move;
      if (selectedAssetId) {
        this.loading = true;
        const update = await this.updateAccount({
          id: this.selectedAccount._id,
          data: { assetId: selectedAssetId, entityId: selectedEntityId }
        });

        if (update) {
          this.$toasted.success('Moved account successfully');
          this.setSelectedAccount({});
          this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1', $deleted: true } } });
          this.$router.push({ name: 'asset-accounts', params: { id: this.$route.params.id } });
          this.loading = false;
          this.modals.move = false;
        }
      } else {
        this.$toasted.error('Please select an asset');
      }
    },
    async onRemove() {
      await this.removeAccount({ id: this.selectedAccount._id });

      if (!this.accountError.remove) {
        this.$toasted.success('Deleted account successfully');
      } else {
        this.$toasted.error('There was an error deleting the account');
      }

      // When an account is deleted the account is moved to 'archived'.
      // Rather than un selected account (which is causing bugs), keep the same account selected.
      // Therefore we also don't need to go another route.

      await this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1', $deleted: true } } });
    }
  }
};
</script>
